@import '@core/styles/vars';

.SuperHubEditor {
  $nav-height: 45px;

  height: 100%;

  &-nav {
    min-height: $nav-height;
  }

  &-container {
    height: calc(100% - $nav-height);
  }

  &-content {
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  &-gate-content {
    display: flex;
  }

  &-spinner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}
